import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import fields from './search-filters.json';
import { query1, query2, query3 } from './search-guide-static';
import operatorDataTransform from './field-metadata-transform';
import CardContainer from '../CardContainer';
import './styles.css';

const RuleCard = ({ operatorData }) => {
	return (
		<CardContainer
			display
			title="RULE clauses"
		>
			<div className="pt-1.5 text-left text-sm">
				GARDIAN’s Advanced Search allows the refinement of your search
				via the definition of <b>Rule Clauses</b> over metadata fields
				supported by the GARDIAN model and used as the search’s
				criteria. The rules follow the general pattern:
			</div>
			<div className="py-5 text-left text-sm">
				[metadata field] &lt;operator&gt; “value”
			</div>
			<div className="text-justify text-sm">
				with the operators supported for each metadata field presented
				in the following table.
			</div>
			{/* <Fieldset */}
			{/*	className="fieldset-advanced-search-guide" */}
			{/*	legend="Operators per Metadata Field" */}
			{/*	toggleable */}
			{/*	collapsed */}
			{/*	style={{ marginTop: '1.25rem' }} */}
			{/* > */}
			{/*	<div className="pt-2.5"> */}
			{/*		<DataTable */}
			{/*			value={operatorData} */}
			{/*			rowGroupMode="rowspan" */}
			{/*			groupRowsBy="field" */}
			{/*			sortMode="single" */}
			{/*			sortField="field" */}
			{/*			sortOrder={1} */}
			{/*			className="m-auto max-w-2xl" */}
			{/*		> */}
			{/*			<Column */}
			{/*				field="field" */}
			{/*				header="METADATA FIELD" */}
			{/*				className="text-center" */}
			{/*			/> */}
			{/*			<Column */}
			{/*				field="operator" */}
			{/*				header="OPERATOR" */}
			{/*				className="text-center" */}
			{/*			/> */}
			{/*		</DataTable> */}
			{/*	</div> */}
			{/* </Fieldset> */}
		</CardContainer>
	);
};

const GroupCard = () => {
	return (
		<CardContainer
			title="GROUP clauses"
			display
		>
			<div className="pt-5 text-justify text-sm">
				Rule clauses are organised in <b>Groups</b>, with each group
				connecting its Rule clauses via the application of an AND or OR
				logical operator between all rules belonging to the group.
			</div>
			<div className="pt-5 text-justify text-sm">
				Group clauses function as a single, independent unit within a
				query and can be enclosed in other groups. In this sense,
				grouping clauses is similar to putting parentheses around a
				mathematical equation or logic expression.
			</div>
			<div className="pt-5 text-justify text-sm">
				GARDIAN’s advanced search starts with an upper-level group. This
				upper-level group contains all the Rule clauses or Group clauses
				that need to be defined. The upper-level group uses by default
				an AND logical operator that can be cahnged by the user.
			</div>
			<div className="pt-5 text-justify text-sm">
				The following examples present how the logical expression
				corresponding to a query can be defined in the form of grouped
				clauses.
			</div>
		</CardContainer>
	);
};

const TemporaryCard = () => {
	return (
		<Card>
			<div className="flex flex-col gap-4">
				<div>
					<p className="p-card-title font-semibold">
						RULES CLAUSES
					</p>
					<p className="pt-1.5 text-left text-sm">
						DataLake’s advanced Search allows the refinement of your search via the definition of Rule Clauses over
						variables supported by the DataLake model and used as the search’s criteria. The rules follow the general
						pattern: [variable] &lt;operator&gt; “value” with the operators supported for each variable
						dependent on its type (numeric, categorical, textual).
					</p>
				</div>
				<div>
					<p className="p-card-title font-semibold">
						GROUP CLAUSES
					</p>
					<p className="pt-1.5 text-left text-sm">
						Rule clauses are organised in Groups, with each group connecting its Rule clauses via the application of
						an AND or OR logical operator between all rules belonging to the group.<br /><br />
						Group clauses function as a single, independent unit within a query and can be enclosed in other groups.
						In this sense, grouping clauses is similar to putting parentheses around a mathematical equation or logic
						expression.<br /><br />
						DataLake’s advanced search starts with an upper-level group. This upper-level group contains all the Rule
						clauses or Group clauses that need to be defined. The upper-level group uses by default an AND logical
						operator that can be changed by the user.
					</p>
				</div>
			</div>
		</Card>
	);
};
const QueryCard = ({
	header,
	subHeader,
	children,
}) => {
	return (
		<CardContainer
			title={header}
			display
		>
			<p className="pb-2.5 pt-7 text-center text-xl">{subHeader}</p>
			<CardContainer display>
				<div className="flex flex-row justify-between">
					<div className="ml-40">
						<div className="text-l text-center font-bold">
							QUERY LOGICAL EXPRESSION
						</div>
						{children[0]}
					</div>
					<div className="mr-40 flex flex-col justify-center">
						<div className="text-l text-center font-bold">
							GROUPED CLAUSES for SEARCH QUERY
						</div>
						{children[1]}
					</div>
				</div>
			</CardContainer>
		</CardContainer>
	);
};

const SearchGuide = ({
	visible,
	setVisible,
}) => {
	return (
		<Dialog
			header="ADVANCED SEARCH GUIDE"
			visible={visible}
			maximizable
			onHide={() => setVisible(false)}
			style={{ width: '80vw' }}
			className="guide"
		>
			{/* <RuleCard */}
			{/*	operatorData={operatorDataTransform(fields.fields)} */}
			{/* /> */}
			{/* <GroupCard /> */}
			<TemporaryCard />
			{/* <QueryCard */}
			{/*	header="Query Example #1" */}
			{/*	subHeader='"I want to find all the resources about maize cultivated in South America"' */}
			{/*	runQuery={() => {}} */}
			{/* > */}
			{/*	{query1.logical} */}
			{/*	{query1.clauses} */}
			{/* </QueryCard> */}
			{/* <QueryCard */}
			{/*	header="Query Example #2" */}
			{/*	subHeader='"I want to find all the resources about maize cultivated in South America"' */}
			{/*	runQuery={() => {}} */}
			{/* > */}
			{/*	{query2.logical} */}
			{/*	{query2.clauses} */}
			{/* </QueryCard> */}
			{/* <QueryCard */}
			{/*	header="Query Example #3" */}
			{/*	subHeader='"I want to find all the resources about maize cultivated in South America"' */}
			{/*	runQuery={() => {}} */}
			{/* > */}
			{/*	{query3.logical} */}
			{/*	{query3.clauses} */}
			{/* </QueryCard> */}
		</Dialog>
	);
};

export default SearchGuide;
