import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import logo from '../../assets/images/Menu/datalake_alpha.png';
import './styles.css';

const LandingPage = () => {
	const navigate = useNavigate();

	return (
		<div className="landing">
			<div className="left">
				<p className="title">
					Standardized agricultural data<br />
					for faster analytics and broader impact
				</p>
				<p className="description">
					With DataLake, discover, generate, and download standards-compliant agricultural data collections
					in different analysis-ready formats.
				</p>
				<Button label="Go to DataLake" onClick={() => navigate('/Home')} />
			</div>
			<img src={logo} alt="logo" />
		</div>
	);
};

export default LandingPage;
