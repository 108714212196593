import React, { useEffect, useMemo, useState } from 'react';
import { AdvancedSearchComponent, constructElasticQuery, removeQueryIds } from '@scioservices/advanced-search-library';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { sortArrayOfObjectsByPropertyValue } from '../../../../../../utils/functions';
import {
	useExportMultiplePackage,
	useGetDataXDatapoolsByIds,
	useGetMultipleJsonResults,
	useGetMultipleSearchResults,
} from '../../../../queryHooks';
import { DynamicTable, SearchGuide } from '../../../../../AdvancedSearchPage/components';
import './styles.css';
import { Loading } from '../../../../../../components';

const downloadOptions = [
	{
		label: 'Schemas',
		code: 'Schemas',
		items: [
			{ label: 'CAROB', value: 'CAROB' },
			{ label: 'ICASA', value: 'ICASA' },
		],
	},
	{
		label: 'Download',
		code: 'download',
		items: [],
	},
];

const AcrossSearch = (props) => {
	const { datapools } = props;

	const [query, setQuery] = useState();
	const [valid, setValid] = useState();
	const [guideVisible, setGuideVisible] = useState(false);
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 50,
		page: 1,
	});
	const [datasetIdExists, setDatasetIdExists] = useState(false);
	const [triggerSearchResults, setTriggerSearchResults] = useState(false);
	const [downloadOption, setDownloadOption] = useState([]);
	const [downloadCase, setDownloadCase] = useState('');

	const mongoQuery = useMemo(() => {
		if (query) {
			return removeQueryIds(query);
		}
		return null;
	}, [query]);

	const elasticQuery = useMemo(() => {
		if (mongoQuery) {
			const elastic = constructElasticQuery(JSON.stringify(mongoQuery));
			return elastic[0];
		}
		return null;
	}, [mongoQuery]);

	const constructedCompositeAlias = useMemo(() => {
		if (datapools.length) {
			let compositeAlias = '';
			datapools.forEach((item) => {
				compositeAlias = `${compositeAlias},${item.alias}`;
			});
			console.log(compositeAlias.substring(1));
			return compositeAlias.substring(1);
		}
		return '';
	}, [datapools.length]);

	const viewDatapool = useGetDataXDatapoolsByIds(datapools.map((item) => item.mongo_id));

	const searchResults = useGetMultipleSearchResults(constructedCompositeAlias, lazyState, elasticQuery, triggerSearchResults, setTriggerSearchResults);

	const pkg = useExportMultiplePackage(constructedCompositeAlias, elasticQuery, downloadCase);

	useEffect(() => {
		if (downloadCase) {
			switch (downloadCase) {
			case 'carobicasa': handleDownload(pkg); break;
			case 'carob': handleDownload(pkg); break;
			case 'icasa': handleDownload(pkg); break;
			default: break;
			}
		}
	}, [downloadCase]);

	const handleDownload = async (downloadFile) => {
		const url = await downloadFile.refetch();
		if (url.data) {
			const link = document.createElement('a');
			link.href = url.data;
			link.setAttribute('download', '');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const filters = () => {
		if (!datapools.length) return [];
		if (!viewDatapool.isFetched) return [];
		let newFilters = viewDatapool.data;
		if (!(newFilters instanceof Array)) return [];
		newFilters = newFilters.map((item) => {
			const temp = { ...item, valueEditorType: item.type };
			if (item.values) {
				let sortedValues = sortArrayOfObjectsByPropertyValue(item.values, 'label');
				sortedValues = sortedValues.map((val) => {
					let newLabel = val.label;
					let newName = val.name;
					if (val.label === 'na') {
						newLabel = 'NA';
					}
					if (val.name === 'na') {
						newName = 'NA';
					}
					return { label: newLabel, name: newName };
				});
				temp.values = sortedValues;
			}
			delete temp.validator;
			return temp;
		});
		newFilters = sortArrayOfObjectsByPropertyValue(newFilters, 'label');
		return newFilters;
	};

	const getSearchResults = () => {
		if (query && valid) {
			setTriggerSearchResults(true);
		}
	};

	const downloadCases = () => {
		const carob = downloadOption.includes('CAROB');
		const icasa = downloadOption.includes('ICASA');
		if (carob && icasa) {
			setDownloadCase('carobicasa');
		} else if (carob) {
			setDownloadCase('carob');
		} else if (icasa) {
			setDownloadCase('icasa');
		}
	};

	const renderSearchResults = () => {
		const groupedItemTemplate = (option) => {
			if (option.label === 'Download') {
				return (
					<Button
						label="Download"
						onClick={() => {
							downloadCases();
						}}
					/>
				);
			}
			return (
				<div className="flex align-items-center">
					<div>{option.label}</div>
				</div>
			);
		};

		return (
			<Loading loading={searchResults.isLoading}>
				{
					searchResults.isSuccess
						? (
							<div className="table-containers">
								<div className="header">
									<p>Results</p>
									{query && valid
										? (
											<div className="header-buttons">
												<MultiSelect
													value={downloadOption}
													options={downloadOptions}
													onChange={(e) => setDownloadOption(e.value)}
													optionLabel="label"
													optionGroupLabel="label"
													optionGroupChildren="items"
													optionGroupTemplate={groupedItemTemplate}
													display="chip"
													// className="w-full md:w-20rem"
													placeholder="Download"
													showSelectAll={false}
												/>
											</div>
										)
										: null}
								</div>
								<DynamicTable
									setDatasetIdExists={setDatasetIdExists}
									datasetIdExists={datasetIdExists}
									searchResults={searchResults}
									lazyState={lazyState}
									setlazyState={setlazyState}
									getSearchResults={getSearchResults}
									setTriggerSearchResults={setTriggerSearchResults}
								/>
							</div>
						)
						: (
							<div className="table-containers">
								<p>Utilize the Search interface to craft your desired query</p>
							</div>
						)
				}
			</Loading>
		);
	};

	return (
		<div className="search-across">
			<div className="search-container">
				{
					viewDatapool.isSuccess
						? (
							<>
								<AdvancedSearchComponent
									addIcon={<i className="fa-solid fa-plus" />}
									deleteIcon={<i className="fa-solid fa-x" />}
									query={query}
									setValid={setValid}
									setQuery={setQuery}
									filters={filters()}
								/>
								<div className="actions">
									<Button
										label="Search"
										disabled={!valid}
										onClick={() => getSearchResults()}
									/>
									<Button
										className="h-11 w-28"
										label="Guide"
										iconPos="left"
										icon="fa-regular fa-book"
										style={{
											background: 'rgb(0, 150, 136)',
											borderColor: 'rgb(1,134,121)',
										}}
										onClick={() => setGuideVisible(true)}
									/>
								</div>
							</>
						)
						: null
				}

			</div>
			{renderSearchResults()}
			<SearchGuide visible={guideVisible} setVisible={setGuideVisible} />
		</div>
	);
};

export default AcrossSearch;
