import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';

const Filters = (props) => {
	const { data, setFilterSelections, filterSelections } = props;

	const disabled = data?.datapools?.length === 1;

	if (!data.datapools?.length || data.datapools?.length === 1) {
		return null;
	}

	return (
		<div className="filters">
			<MultiSelect
				value={filterSelections.crops}
				onChange={(e) => setFilterSelections({ ...filterSelections, crops: e.value })}
				options={data?.filters?.crops || []}
				placeholder="Select Crop"
				maxSelectedLabels={3}
				selectAllLabel="Select All"
				disabled={disabled}
			/>
			<MultiSelect
				value={filterSelections.regions}
				onChange={(e) => setFilterSelections({ ...filterSelections, regions: e.value })}
				options={data?.filters?.regions || []}
				placeholder="Select Region"
				maxSelectedLabels={3}
				disabled={disabled}
				selectAllLabel="Select All"
			/>
			<MultiSelect
				value={filterSelections.countries}
				onChange={(e) => setFilterSelections({ ...filterSelections, countries: e.value })}
				options={data?.filters?.countries || []}
				placeholder="Select Country"
				maxSelectedLabels={3}
				disabled={disabled}
				selectAllLabel="Select All"
			/>
			<Button
				label="Filter"
				onClick={() => {
					setFilterSelections({ ...filterSelections, filter: true });
				}}
				disabled={(!filterSelections.crops.length && !filterSelections.regions.length && !filterSelections.countries.length) || disabled}
			/>
			<Button
				label="Clear"
				onClick={() => {
					setFilterSelections({ crops: [], countries: [], regions: [], filter: false });
				}}
				disabled={(!filterSelections.crops.length && !filterSelections.regions.length && !filterSelections.countries.length) || disabled}
			/>
		</div>
	);
};

export default Filters;
