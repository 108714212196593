import React, { useEffect, useState } from 'react';
import UploadComponent from '@scioservices/upload-component';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import './styles.css';
import { useUpload } from '../../queryHooks';

const DataFilesUploader = ({ token }) => {
	const { user } = useAuth0();
	const [completedUploads, setCompletedUploads] = useState([]);

	const queryClient = useQueryClient();

	const confirmUpload = useUpload(user, setCompletedUploads, queryClient);

	useEffect(() => {
		if (completedUploads.length > 0) {
			confirmUpload.mutate(completedUploads);
		}
	}, [completedUploads]);

	return (
		<div className="my-data-files-uploader">
			<UploadComponent
				completedUploads={completedUploads}
				setCompletedUploads={setCompletedUploads}
				devUrl={process.env.REACT_APP_BACKEND_URL}
				accessToken={token}
				uppyType="dashboard"
				restrictions={{ allowedFileTypes: ['.csv', '.xlsx', '.xlx'] }}
				// locale={{ youCanOnlyUploadFileTypes: 'hi' }}
				// locale={{ strings: { youCanOnlyUploadFileTypes: 'hi' } }}
				note="Upload your data in tabular format. Currently supported file formats are CSV and MS Excel (tm)"
			/>
		</div>

	);
};

export default DataFilesUploader;
