import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import './styles.css';
import { Button } from 'primereact/button';
import { Profile } from '../../../../components';
import { useGetSearchResults } from '../../queryHooks';

const DynamicTable = (props) => {
	const { searchResults, lazyState, setlazyState, datasetIdExists, setDatasetIdExists, setTriggerSearchResults } = props;

	const [columns, setColumns] = useState([]);
	const [activeColumns, setActiveColumns] = useState([]);
	const [profileDialog, setProfileDialog] = useState(false);

	useEffect(() => {
		if (!columns.length || profileDialog) return;
		const savedSelections = JSON.parse(localStorage.getItem('variables'));
		if (savedSelections) {
			const properties = Object.keys(savedSelections);
			const filterSelections = [];
			properties.forEach((item) => {
				const categorySelections = savedSelections[item].target;
				const temp = categorySelections.map((selection) => selection.name);
				filterSelections.push(...temp);
			});
			setActiveColumns(filterSelections);
		} else {
			// const array = ['country', 'latitude', 'longitude', 'planting_date'];
			setActiveColumns(columns);
		}
	}, [columns, profileDialog]);

	useEffect(
		() => {
			if (searchResults.data.documents) {
				const properties = Object.keys(searchResults.data.documents[0]);
				const temp = properties.filter((item) => {
					if (typeof searchResults.data.documents[0][item] === 'string' || typeof searchResults.data.documents[0][item] === 'number') {
						return true;
					}
					return false;
				});
				if (temp.find((item) => item === 'dataset_id')) {
					setDatasetIdExists(true);
				}
				const newColumns = temp.filter((item) => item !== 'dataset_id');
				const columnsToRender = newColumns.filter((item) => !item.includes('enriched_'));
				setColumns([...temp]);
			}
		}, [searchResults.data]
	);

	const onPage = (event) => {
		setlazyState(event);
		setTriggerSearchResults(true);
	};

	const columnHeader = (item) => {
		return <p className="column-header">{item}</p>;
	};

	const renderValue = (rowData, item) => {
		if (rowData[item] === -2147483648) {
			return 'NA';
		} 
		return rowData[item];
	};
	const renderColumns = () => {
		return activeColumns.map((item) => {
			return (
				<Column
					field={item}
					header={columnHeader(item)}
					body={(rowData) => renderValue(rowData, item)}
				/>
			);
		});
	};

	const header = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
				{/* <MultiSelect */}
				{/*	selectAllLabel="Select All" */}
				{/*	maxSelectedLabels={3} */}
				{/*	value={activeColumns} */}
				{/*	options={columns} */}
				{/*	onChange={(e) => setActiveColumns(e.value)} */}
				{/* /> */}
				<Button label="Select Variables" onClick={() => setProfileDialog(true)} />
			</div>
		);
	};

	if (!searchResults.data.documents) {
		return null;
	}

	return (
		<div className="dynamic-table">
			<DataTable
				value={searchResults.data.documents}
				showGridlines
				stripedRows
				header={header}
				paginator
				first={lazyState.first}
				rows={lazyState.rows}
				rowsPerPageOptions={[5, 10, 25, 50]}
				totalRecords={searchResults.data.total}
				onPage={onPage}
				lazy
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="{first} - {last} of {totalRecords}"
			>
				{datasetIdExists ? <Column field="dataset_id" header={columnHeader('dataset_id')} /> : null}
				{renderColumns()}
			</DataTable>
			<Profile profileDialog={profileDialog} setProfileDialog={setProfileDialog} />
		</div>
	);
};

export default DynamicTable;
