import { Divider } from 'primereact/divider';
import React from 'react';

export const query1 = {
	logical: (
		<>
			<div className="pt-1.5 text-left text-sm">
				( Controlled Term = “<span className="text-red-600">maize</span>
				” ) <span className="font-bold">AND</span>
			</div>
			<div className="pt-1.5 text-left text-sm">
				( Region = “<span className="text-red-600">South America</span>”
				)
			</div>
		</>
	),
	clauses: (
		<>
			<div className="pt-1.5 text-left text-sm">
				<span className="font-bold text-blue-600">GROUP</span> [
				<span className="font-bold">AND</span> operator]
			</div>
			<div className="flex flex-row">
				<Divider
					layout="vertical"
					className="!mx-3 w-0.5 bg-blue-600"
				/>
				<div>
					<div className="pt-1.5 text-left text-sm">
						<span className="text-green-600">RULE:</span> [
						Controlled Term ] is “
						<span className="text-red-600">maize</span>”
					</div>
					<div className="pt-1.5 text-left text-sm">
						<span className="text-green-600">RULE:</span> [ Region ]
						is “<span className="text-red-600">South America</span>”
						)
					</div>
				</div>
			</div>
		</>
	),
};

export const query2 = {
	logical: (
		<>
			<div className="pt-1.5 text-left text-sm">
				( Controlled Term = “
				<span className="text-red-600">food security</span>” ){' '}
				<span className="font-bold">AND</span>
			</div>
			<div className="pt-1.5 text-left text-sm">
				( Provider = “<span className="text-red-600">IFPRI</span>” ){' '}
				<span className="font-bold">AND</span>
			</div>
			<div className="pt-1.5 text-left text-sm">
				( Funder = “
				<span className="text-red-600">World Bank Group (WB)</span>” )
			</div>
		</>
	),
	clauses: (
		<>
			<div className="pt-1.5 text-left text-sm">
				<span className="font-bold text-blue-600">GROUP</span> [
				<span className="font-bold">AND</span> operator]
			</div>
			<div className="flex flex-row">
				<Divider
					layout="vertical"
					className="!mx-3 w-0.5 bg-blue-600"
				/>
				<div>
					<div className="pt-1.5 text-left text-sm">
						<span className="text-green-600">RULE:</span> [
						Controlled Term ] is “
						<span className="text-red-600">food security</span>”
					</div>
					<div className="pt-1.5 text-left text-sm">
						<span className="text-green-600">RULE:</span> [ Provider
						] is “<span className="text-red-600">IFPRI</span>” )
					</div>
					<div className="pt-1.5 text-left text-sm">
						<span className="text-green-600">RULE:</span> [ Funder ]
						is “
						<span className="text-red-600">
							World Bank Group (WB)
						</span>
						” )
					</div>
				</div>
			</div>
		</>
	),
};

export const query3 = {
	logical: (
		<>
			<div className="pt-1.5 text-left text-sm">
				( Any Field = “
				<span className="text-red-600">oryza sativa</span>” ){' '}
				<span className="font-bold">AND</span>
			</div>
			<div className="pt-1.5 text-left text-sm">
				( ( Provider = “
				<span className="text-red-600">Africa Rice Center</span>” ){' '}
				<span className="font-bold">OR </span>( Provider = “
				<span className="text-red-600">IRRI</span>” ) ){' '}
				<span className="font-bold">AND</span>
			</div>
			<div className="pt-1.5 text-left text-sm">
				( ( Country = “<span className="text-red-600">Bangladesh</span>”
				) <span className="font-bold">OR </span>( Country = “
				<span className="text-red-600">India</span>” ){' '}
				<span className="font-bold">OR </span>( Country = “
				<span className="text-red-600">Philippines</span>” ) )
			</div>
		</>
	),
	clauses: (
		<>
			<div className="pt-1.5 text-left text-sm">
				<span className="font-bold text-blue-600">GROUP</span> [
				<span className="font-bold">AND</span> operator]
			</div>
			<div className="flex flex-row">
				<Divider
					layout="vertical"
					className="!mx-3 w-0.5 bg-blue-600"
				/>
				<div>
					<div className="pt-1.5 text-left text-sm">
						<span className="text-green-600">RULE:</span> [ Any
						Field ] contains “
						<span className="text-red-600">oryza sativa</span>”
					</div>
					<div>
						<div className="pb-0 pt-1.5 text-left text-sm">
							<span className="font-bold text-blue-600">
								GROUP
							</span>{' '}
							[<span className="font-bold">OR</span> operator]
						</div>
						<div className="flex flex-row">
							<Divider
								layout="vertical"
								className="!mx-3 w-0.5 bg-blue-600"
							/>
							<div>
								<div className="pt-1.5 text-left text-sm">
									<span className="text-green-600">
										RULE:
									</span>{' '}
									[ Provider ] is “
									<span className="text-red-600">
										Africa Rice Center
									</span>
									” )
								</div>
								<div className="pt-1.5 text-left text-sm">
									<span className="text-green-600">
										RULE:
									</span>{' '}
									[ Funder ] is “
									<span className="text-red-600">IRRI</span>”
									)
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="pb-0 pt-1.5 text-left text-sm">
							<span className="font-bold text-blue-600">
								GROUP
							</span>{' '}
							[<span className="font-bold">OR</span> operator]
						</div>
						<div className="flex flex-row">
							<Divider
								layout="vertical"
								className="!mx-3 w-0.5 bg-blue-600"
							/>
							<div className="p-col-11">
								<div className="pt-1.5 text-left text-sm">
									<span className="text-green-600">
										RULE:
									</span>{' '}
									[ Country ] is “
									<span className="text-red-600">
										Bangladesh
									</span>
									”
								</div>
								<div className="pt-1.5 text-left text-sm">
									<span className="text-green-600">
										RULE:
									</span>{' '}
									[ Country ] is “
									<span className="text-red-600">India</span>”
									)
								</div>
								<div className="pt-1.5 text-left text-sm">
									<span className="text-green-600">
										RULE:
									</span>{' '}
									[ Country ] is “
									<span className="text-red-600">
										Philippines
									</span>
									” )
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	),
};
