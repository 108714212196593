/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import EiA from '../../assets/images/Footer/EiA_logo.png';
import CgiarLogo from '../../assets/images/Footer/CGIAR_white.png';
import footerContent from './assets/footer-content';
import './styles.css';

const FooterDialog = ({
	type,
	dialogStatus,
	setDialogStatus,
}) => {
	const getDialogContent = (dialogType) => {
		switch (dialogType) {
		case 'PRIVACY POLICY':
			return footerContent.privacy;
		case 'TERMS OF USE':
			return footerContent.terms;
		default:
			return null;
		}
	};

	return (
		<Dialog
			header={type}
			visible={dialogStatus}
			onHide={() => {
				if (!dialogStatus) return;
				setDialogStatus(false);
			}}
			style={{ width: '80%' }}
		>
			{footerContent.common}
			{getDialogContent(type)}
		</Dialog>
	);
};

const Footer = () => {
	const [dialogStatus, setDialogStatus] = useState(false);
	const [dialogType, setDialogType] = useState('privacy');

	const toggleDialog = (type) => {
		setDialogType(type);
		setDialogStatus(true);
	};
	return (
		<div className="footer">
			<div className="content">
				<div className="upper">
					<div className="icons">
						<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer">
							<i className="fab fa-creative-commons license-icons" />
						</a>
						<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer">
							<i className="fab fa-creative-commons-by license-icons" />
						</a>
						<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer">
							<i className="fab fa-creative-commons-nc license-icons" />
						</a>
						<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer">
							<i className="fab fa-creative-commons-sa license-icons" />
						</a>
					</div>
					<div className="logos">
						<a href="https://www.cgiar.org/initiative/excellence-in-agronomy/" target="_blank" rel="noreferrer">
							<img src={EiA} alt="EiA" />
						</a>
						<a href="https://www.cgiar.org" target="_blank" rel="noreferrer">
							<img className="cgiar" src={CgiarLogo} alt="cgiar" />
						</a>
					</div>
				</div>
				<div className="lower">
					<div className="privacy-terms flex flex-row items-center justify-between gap-2">
						<FooterDialog
							type={dialogType}
							dialogStatus={dialogStatus}
							setDialogStatus={setDialogStatus}
						/>
						<Button
							text
							label="Privacy"
							onClick={() => toggleDialog('PRIVACY POLICY')}
						/>
						<div className="separator h-6 w-px bg-white" />
						<Button
							text
							label="Terms of Use"
							onClick={() => toggleDialog('TERMS OF USE')}
						/>
					</div>
					<a href="https://scio.systems/" target="_blank" rel="noreferrer">
						<p>powered by<span> SCiO</span></p>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
