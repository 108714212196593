import { useQuery, useMutation } from '@tanstack/react-query';
import { http, httpUnintercepted } from '../../services/httpService';

const getPublicDatapools = async () => {
	const result = await httpUnintercepted.get(`/datapools/public/all/get`);
	return result.data;
};

export const useGetPublicDatapools = (activeIndex) => useQuery({
	staleTime: Infinity,
	enabled: activeIndex !== 3,
	queryKey: ['getPublicDatapools'],
	queryFn: () => getPublicDatapools(),
});

const getPrivateDatapools = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/all/get`);
	return result.data;
};

export const useGetPrivateDatapools = (activeIndex, userId) => useQuery({
	staleTime: Infinity,
	enabled: activeIndex === 3,
	queryKey: ['getUserDatapools'],
	queryFn: () => getPrivateDatapools(userId),
});

const getUserPinnedDatapools = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/pinned/get`);
	return result.data;
};

export const useGetPinnedDatapoolIds = (activeIndex, isAuthenticated, user) => useQuery({
	staleTime: Infinity,
	enabled: isAuthenticated && ((activeIndex === 1) || (activeIndex === 2)),
	queryKey: ['getPinnedDatapools'],
	queryFn: () =>
		getUserPinnedDatapools(user.sub)
			.then((res) => {
				return res;
			}),
});

const createDatapool = async (userId, metadata) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/create`, metadata);
	return result.data;
};

export const useCreateDatapool = (user, setDialogStatus, setLoading, queryClient) => useMutation({
	mutationFn: (metadata) => createDatapool(user.sub, metadata),
	onSuccess: () => {
		queryClient.invalidateQueries('getPublicDatapools');
		queryClient.invalidateQueries('getUserDatapools');
		queryClient.invalidateQueries('getPinnedDatapools');
		queryClient.invalidateQueries('getDatapoolById');
		queryClient.invalidateQueries('getDatafileDatapools');
	},
	onSettled: () => {
		setLoading(false);
		setDialogStatus(false);
	},
});

const unpinDatapool = async (userId, datapoolId) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/unpin`);
	return result.data;
};

const pinDatapool = async (userId, datapoolId) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/pin`);
	return result.data;
};
export const useChangePinStatus = (userId, itemData, pinnedDatapools, queryClient) => useMutation({
	mutationFn: (keyword) => {
		if (keyword === 'unpin') {
			return unpinDatapool(userId, itemData.datapool_id);
		} 
		return pinDatapool(userId, itemData.datapool_id);
	},
	onSuccess: () => {
		queryClient.invalidateQueries('getPinnedDatapools');
	},
});

const getDataXDatapoolById = async (id, publicStatus, userId) => {
	if (publicStatus) {
		const result = await http.get(`/datapools/public/${id}/get`);
		return result.data;
	}
	const result = await http.get(`user/${userId}/datapools/${id}/get`);
	return result.data;
};

export const useGetDataXDatapoolById = (selectedDatapool, userId) => useQuery({
	staleTime: Infinity,
	enabled: !!selectedDatapool.alias,
	queryKey: ['getDatapoolById', selectedDatapool.alias],
	queryFn: () =>
		getDataXDatapoolById(selectedDatapool.mongo_id, selectedDatapool.status === 'public', userId),
});

const getDataXDatapoolsByIds = async (datapoolIds) => {
	const result = await http.post(`/datapools/public/dataxDatapools/get`, datapoolIds);
	return result.data;
};

export const useGetDataXDatapoolsByIds = (datapoolIds) => useQuery({
	staleTime: Infinity,
	enabled: !!datapoolIds.length,
	queryKey: ['getDatapoolsByIds'],
	queryFn: () =>
		getDataXDatapoolsByIds(datapoolIds),
});

const getMultipleDatapoolSearchResults = async (alias, from, to, query) => {
	const result = await httpUnintercepted.post(`/datapools/public/search/${alias}/multiple_documents/${from}/${to}`, { ...query });
	return result.data;
};

export const useGetMultipleSearchResults = (alias, lazyState, elasticQuery, triggerSearchResults, setTriggerSearchResults) => useQuery({
	staleTime: Infinity,
	enabled: !!triggerSearchResults,
	queryKey: ['getMultipleDatapoolSearchResults', alias, lazyState, elasticQuery],
	queryFn: () => {
		const json = JSON.parse(elasticQuery);
		const query = JSON.parse(json.query);
		return getMultipleDatapoolSearchResults(alias, lazyState.first, lazyState.first + lazyState.rows, query)
			.finally(() => {
				setTriggerSearchResults(false);
			});
	},
});

const getJsonResults = async (index, query, type) => {
	const result = await httpUnintercepted.post(`datapools/public/search/get/${type}/${index}`, { ...query });
	return result.data;
};

export const useGetMultipleJsonResults = (alias, elasticQuery, type) => useQuery({
	gcTime: 0,
	enabled: false,
	queryKey: ['exportMultipleCsv', alias, elasticQuery, type],
	queryFn: () => {
		const json = JSON.parse(elasticQuery);
		const query = JSON.parse(json.query);
		return getJsonResults(alias, query, type);
	},
});

const exportPackage = async (index, query, choice) => {
	const body = {
		query,
	};
	const result = await httpUnintercepted.post(`datapools/public/search/homepage/${choice}/${index}`, body);
	return result.data;
};

export const useExportMultiplePackage = (alias, elasticQuery, choice) => useQuery({
	gcTime: 0,
	enabled: false,
	queryKey: ['exportMultiplePackage', alias, elasticQuery, choice],
	queryFn: () => {
		const json = JSON.parse(elasticQuery);
		const query = JSON.parse(json.query);
		return exportPackage(alias, query, choice);
	},
});
