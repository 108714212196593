/* eslint-disable class-methods-use-this */
import { http } from '../index';

class DbService {
	addUser = async (user) => {
		const data = {
			name: user.name ? user.name : '',
			surname: user.surname ? user.surname : '',
			email: user.email,
		};
		return http.post(`/user/${user.sub}/add`, data);
	};
}

export default new DbService();
