import React from 'react';

const footerContent = {
	privacy: (
		<div className="privacy-terms-of-use">
			<p className="title">
				1.	WHAT TYPE OF PERSONAL DATA DO WE COLLECT FROM USERS AND HOW DO WE MANAGE IT?
			</p>
			<p>
				When signing up to DataLake, we may ask you for information such as your name, last name, email.
				Providing this information might be mandatory to create an account and use DataLake. We respect user
				privacy; accordingly, any personally identifiable information you provide is protected by SSL
				encryption when it is exchanged with DataLake and will only be used for internal purposes in accordance
				with site-specific policies, or in ways to which you have explicitly consented. This information is
				password-protected, accessible only by designated staff who require this information to perform their
				duties and is retained only for as long as reasonably needed for the purpose you have consented to or
				as required by law. We do not market, sell or transfer personally identifiable information to third
				parties unless required by law.
			</p>
			<p className="title">
				2.	DO WE USE COOKIES AND AUTOMATED TRACKING TOOLS?
			</p>
			<p>
				We use cookies to collect anonymous information and aggregate it to help us better understand how users
				interact with DataLake. Our use of cookies or other automated tracking tools or services does not
				involve the collection of any personally identifiable information. As an example, we use Google
				Analytics software to temporarily store and analyze minimal information about your visit
				(country, time of navigation on DataLake, number of visits per day and per month), however,
				this information cannot be used by us to identify you as an individual. <br />
				<i>
					If you do not wish to have session or persistent cookies placed on your computer, you can disable them
					at any time from your web browser. If you opt out of cookies, you will still have access to all
					DataLake information and resources, but you may not be able to use cookie-dependent features.
				</i>
			</p>
			<p className="title">
				3.	THIRD-PARTY SITES OR SERVICES
			</p>
			<p>
				DataLake may link to third-party sites or services. Since we do not control these, we are not
				responsible for their privacy practices and you use such sites and services entirely at your own
				risk. We encourage you to review the privacy policies of third-party sites or services if you are
				sharing any personally identifiable information with them.
			</p>
		</div>
	),
	terms: (
		<div className="privacy-terms-of-use">
			<p>
				The terms and conditions, which include our Privacy and Data Protection Policy and Use of the Site,
				govern your access to and use of the DataLake website. DataLake is managed by SCiO, under the aegis of
				the Excellence in Agronomy Initiative which is administered by the International Institute of Tropical
				Agriculture. IITA is an independent international research for development organization member of the
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://www.cgiar.org/'}
					rel="noreferrer"
				>
					{' '}
					CGIAR.{' '}
				</a><br />
				SCiO and EiA are committed to protecting user privacy. This notice explains what happens to any
				personal data or personal information that you provide, or any data or information that we may
				collect from or about you when you use DataLake. It also explains our practices regarding personally 
				identifiable information of third parties, which may be accessible or discoverable through DataLake.<br />
				By accessing or using Data Lake, you agree to be bound by these Terms. In addition, regional, national
				or local laws may apply to the access and use of DataLake and you are responsible to comply with them. 
				If you do not agree to be bound by these Terms, please promptly exit DataLake.<br />
				SCiO and EiA reserves the right to modify these Terms at their discretion at any time,
				such modifications being effective when posted. <br /> <br />
				General principles of law, including the UNIDROIT Principles of International Commercial Contracts
				of 2016, govern these Terms. In case of any dispute, controversy or claim arising out of or relating
				to these Terms, you and SCiO and EIA will use their best efforts to resolve the issues amicably.
				In case of not reaching an agreement, the dispute will be resolved by arbitration in accordance with
				the UNCITRAL Arbitration Rules in effect on the date of the dispute proceedings.<br /><br />
				If you have any questions or concerns about these Terms, or if you have questions about your privacy
				rights, want to review any Personal Data, correct, withdraw your consent, delete your Personal Data,
				lodge a complaint, or raise a concern, please contact us at
				<a
					className="font-semibold"
					target={'_blank'}
					href={'mailto:info@scio.systems'}
					rel="noreferrer"
				>
					{' '}
					info@scio.systems.{' '}
				</a>
			</p>
			<p className="section-title">
				TERMS OF USE OF DATA LAKE
			</p>
			<p className="title">
				1.	YOUR PRIVACY
			</p>
			<p>
				We respect user privacy and manage any personally identifiable information collected by us from you
				through DataLake in accordance with our PRIVACY POLICY.
			</p>
			<p className="title">
				2.	COPYRIGHT
			</p>
			<p>
				Copyright © 2023 Excellence in Agronomy - CGIAR<br />
				DataLake is an online interface conceived to provide access to aggregated, thematic collections of
				data. SCiO and EiA hold the title to the online DataLake and to the materials and content generated by
				DataLake unless otherwise specified.
			</p>
			<p className="title">
				3.	USE LICENSE
			</p>
			<p>
				DataLake is licensed under the
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://creativecommons.org/licenses/by-nc-sa/4.0/'}
					rel="noreferrer"
				>
					{' '}
					CC BY-NC-SA 4.0 DEED{' '}
				</a><br /><br />
				DataLake is free software: you can redistribute it and/or modify it under the terms of the
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://creativecommons.org/licenses/by-nc-sa/4.0/'}
					rel="noreferrer"
				>
					{' '}
					CC BY-NC-SA 4.0 DEED{' '}
				</a>.<br />
				DataLake is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the
				implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://creativecommons.org/licenses/by-nc-sa/4.0/'}
					rel="noreferrer"
				>
					{' '}
					CC BY-NC-SA 4.0 DEED{' '}
				</a> for
				more details.
			</p>
			<p className="title">
				4.	DISCLAIMER
			</p>
			<p>
				We strive to ensure that DataLake and its contents are accurate and current. However, we do not accept
				liability for any losses or damages which may arise from using, relying on, or otherwise associated with
				DataLake. Except to the extent prohibited by law no warranties or guarantees are provided as to the
				accuracy, reliability, ownership, non-infringement, fitness for a particular purpose, security or any
				legal or regulatory compliance status of any content or service that is accessible or discoverable
				through DataLake including through websites, platforms, tools or services linked to or associated with
				DataLake. In no event shall the administrators of DataLake be liable for any loss or damages of any
				nature arising in connection with access to or use of any such content except to the extent such
				liability cannot be excluded or limited under applicable law.
			</p>
			<p className="title">
				5.	ACCEPTABLE USE
			</p>
			<p>
				You may not use DataLake or its content in any way that:
				<ul>
					<li>disrupts the normal working of the website</li>
					<li>infringes any copyright, patent, trademark, trade secret, other proprietary rights, or rights of publicity or privacy in any way</li>
					<li>is illegal or otherwise unlawful</li>
					<li>has not been requested, including unsolicited bulk email or spamming</li>
					<li>is obscene, vulgar abusive, menacing or offensive or may cause or result in harassment, harm, distress or inconvenience to any persons or communities</li>
					<li>facilitates the transmission of any virus or other material that could potentially damage the website, and any computers or internet services that are connected to it</li>
					<li>unfairly impairs the reputation of DataLake, the organizations responsible for its administration, or any organizations or individuals that have contributed to or made content accessible or discoverable through DataLake (this does not include genuine user feedback regarding the site)</li>
				</ul>
				You must not attempt to interfere with the website or its workings. In particular, you must not try to
				break security, tamper with, hack into, or otherwise disrupt any computer system, server, website,
				router, or any other device. If we become aware of any violation of these Terms, we reserve the right
				to take action to stop the violation. This may include removing information, suspending, blocking or
				removing a user, and providing relevant information to external bodies where required by law or
				regulation.
			</p>
			<p className="title">
				6.	USE OF WEBSITE, IMAGES AND LOGOS ASSOCIATED WITH DATA Lake
			</p>
			<p>
				The images and logos associated with DataLake are subject to copyright. You agree to not use any logo
				or image without prior written authorization from the relevant copyright owner. For the avoidance of
				doubt, the name, website, images and logos associated with DataLake may not be used for advertising,
				product endorsement, or any other purpose without our prior written consent.
			</p>
		</div>
	),
};

export default footerContent;
