import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Gleap from 'gleap';
import { useAuth0 } from '@auth0/auth0-react';
import {
	About,
	AddVersion,
	AdvancedSearchPage,
	CropModel,
	DataCleaner,
	EditMetadata,
	Home,
	MyApis,
	MyDataFiles,
	MyDataPools,
	LandingPage,
} from './pages';
import { Footer, Loader, MenuBar, PrivateOrStateDependantRoute, UserValidation } from './components';
import { GeneralContextProvider, UserProvider } from './store';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import './App.css';
import { useGetToken, useValidate } from './hooks';
import { http } from './services/httpService';
import useSockets from './hooks/useSockets';
import { useUpdateAuth0UserMetadata } from './queryHooks';

const App = () => {
	const [token, setToken] = useState(false);
	const [init, setInit] = useState(false);
	const [counter, setCounter] = useState(0);

	const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

	const updateUserAuth0Metadata = useUpdateAuth0UserMetadata(user, getAccessTokenSilently);

	useGetToken(setToken, setInit);
	useValidate(token, setInit);
	// useAcceptInvitations(token, init);
	useSockets();

	useEffect(() => {
		// if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
		Gleap.initialize('PXci16hQQKDCacdz0eIMzZ11IpKUNfiZ');
		// }

		const reqInterceptor = http.interceptors.request.use(
			(config) => {
				setCounter((prevState) => prevState + 1);
				return config;
			},
			(error) => {
				setCounter((prevState) => prevState - 1);
				// console.log(error);
				return Promise.reject(error);
			}
		);
		const resInterceptor = http.interceptors.response.use(
			(response) => {
				setCounter((prevState) => prevState - 1);
				return response;
			},
			(error) => {
				setCounter((prevState) => prevState - 1);
				// console.log(error);
				return Promise.reject(error);
			}
		);
		return () => {
			http.interceptors.request.eject(reqInterceptor);
			http.interceptors.response.eject(resInterceptor);
		};
	}, []);

	useEffect(
		() => {
			if (isAuthenticated && token) {
				if (!user?.survey) {
					updateUserAuth0Metadata.mutate({ completedSurvey: true });
					Gleap.showSurvey('gut275r', 'survey');
				}
			}
		}, [isAuthenticated, token]
	);

	return (
		init
			? (
				<div className="app">
					{counter ? <Loader /> : null}
					<UserProvider>
						<GeneralContextProvider>
							<MenuBar token={token} />
							<Routes>
								<Route exact path="/" element={<Navigate replace to="/LandingPage" />} />
								<Route path="/Home" element={<Home />} />
								<Route path="/LandingPage" element={<LandingPage />} />
								<Route path="/AdvancedSearch" element={<AdvancedSearchPage />} />
								<Route path="/About" element={<About />} />
								<Route path="/MyDataPools" element={<PrivateOrStateDependantRoute privateRoute><MyDataPools /></PrivateOrStateDependantRoute>} />
								<Route path="/Tools/DataCleaner" element={<PrivateOrStateDependantRoute privateRoute><DataCleaner /></PrivateOrStateDependantRoute>} />
								<Route path="/Tools/CropModel" element={<PrivateOrStateDependantRoute privateRoute><CropModel /></PrivateOrStateDependantRoute>} />
								<Route path="/MyDataPools/AccessControl" element={<PrivateOrStateDependantRoute privateRoute stateDependentRoute><MyDataPools tab={{ name: 'AccessControl', index: 0 }} /></PrivateOrStateDependantRoute>} />
								<Route path="/MyDataPools/EditData" element={<PrivateOrStateDependantRoute privateRoute stateDependentRoute><MyDataPools tab={{ name: 'EditData', index: 1 }} /></PrivateOrStateDependantRoute>} />
								<Route path="/MyDataPools/EditData/AddVersion" element={<PrivateOrStateDependantRoute privateRoute stateDependentRoute><AddVersion token={token} /></PrivateOrStateDependantRoute>} />
								<Route path="/MyDataPools/EditData/Metadata" element={<PrivateOrStateDependantRoute privateRoute stateDependentRoute><EditMetadata token={token} /></PrivateOrStateDependantRoute>} />
								<Route path="/MyDataFiles" element={<PrivateOrStateDependantRoute privateRoute><MyDataFiles token={token} /></PrivateOrStateDependantRoute>} />
								<Route path="/Home/Landing" element={<PrivateOrStateDependantRoute privateRoute><UserValidation /></PrivateOrStateDependantRoute>} />
								<Route path="/MyApis" element={<PrivateOrStateDependantRoute privateRoute><MyApis /></PrivateOrStateDependantRoute>} />
							</Routes>
							<Footer />
						</GeneralContextProvider>
					</UserProvider>
				</div>
			)
			: null
	);
};

export default App;
