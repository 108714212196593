/* eslint-disable */
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useAuth0 } from '@auth0/auth0-react';
import { PickList } from 'primereact/picklist';
import variables from './variables.json';

const Variable = (props) => {
	const { categoryVariables, item, filterSelections, setFilterSelections } = props;

	const savedSelections = JSON.parse(localStorage.getItem('variables'));

	const [source, setSource] = useState(savedSelections ? (savedSelections[item] ? savedSelections[item].source : categoryVariables) : categoryVariables);
	const [target, setTarget] = useState(savedSelections ? (savedSelections[item] ? savedSelections[item].target : []) : []);

	const onChange = (e) => {
		setSource(e.source);
		setTarget(e.target);
		const selections = { source: e.source, target: e.target };
		const newFilters = { ...filterSelections };
		newFilters[item] = selections;
		localStorage.setItem('variables', JSON.stringify(newFilters));
		setFilterSelections(newFilters);
	};

	const itemTemplate = (it) => {
		return (
			<div className="flex flex-wrap p-2 align-items-center gap-3">
				<p className="font-bold">{it.name}</p>
			</div>
		);
	};

	return (
		<PickList
			dataKey="name"
			source={source}
			target={target}
			itemTemplate={itemTemplate}
			onChange={onChange}
			sourceHeader={item}
		/>
	);
};

const Profile = (props) => {
	const { profileDialog, setProfileDialog } = props;
	const { user } = useAuth0();

	const [filterSelections, setFilterSelections] = useState(JSON.parse(localStorage.getItem('variables')) || {});

	const renderVariables = () => {
		const categories = [];
		variables.forEach((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});
		const validVariables = variables.filter((item) => item.name !== 'name');
		return categories.map((item) => {
			const categoryVariables = validVariables.filter((variable) => variable.category === item);
			return <Variable item={item} categoryVariables={categoryVariables} filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
		});
	};

	return (
		<Dialog header="Profile" visible={profileDialog} style={{ width: '80vw' }} onHide={() => { if (!profileDialog) return; setProfileDialog(false); }}>
			{/*<p>Name: {user?.user_metadata?.name} {user?.user_metadata?.surname}</p>*/}
			<p>Email: {user?.email}</p>
			<p>Select the carob variables subset relevant to your work.</p>
			{renderVariables()}
		</Dialog>
	);
};

export default Profile;
